/** Libs */

import { IconClose, IconHeart, IconShare2, IconTime } from "components/icons";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactInstaStories from "react-insta-stories";
import { Autoplay, EffectCoverflow, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import heartAnim from "assets/img/icon/misc/rain-heart.png"
import CopyToClipboard from "react-copy-to-clipboard";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
/** Global Components */

/** Local Components */

/** Assets */

/** Utils */

export default function Story({ stories = [], isOpen, closeStory, from = 0, setState, activeStoryIndex, swiperRef }) {
  // State  
  const [activeStory, activeStorySet] = useState(0);
  const [isFirstTime, isFirstTimeSet] = useState(true);

  // Hooks
  const router = useRouter();
  // Func  

  // Use Effect  
  useEffect(() => {
    if (router.query?.story) {
      const splitStoryQuery = router?.query.story.split("_");
      const storyProfile = splitStoryQuery[0];
      const storyProfileInsideIndex = splitStoryQuery[1];

      const openByQuery = setTimeout(() => {
        setState({ isOpenStory: true, startFrom: stories.findIndex(i => i.map(ii => ii?.slug).includes(storyProfile)), activeStoryIndex: parseInt(storyProfileInsideIndex) });
        router.replace(router.pathname, undefined, { shallow: true });
      }, 2000);
      return () => clearTimeout(openByQuery);
    }
  }, [router])


  // console.log(stories)    
  useEffect(() => {
    if (!isOpen) {
      activeStorySet(0)
    }
  }, [isOpen])  

  return (
    <AnimatePresence>
      {isOpen &&
        <>
          <motion.div
            drag='y'
            dragConstraints={{ top: 0, bottom: 20, }}
            onDragEnd={(e, { offset: { y } }) => {
              if (y >= 10) {
                closeStory();
              }
            }}
            className="story-custom-container w-100"
            initial={{ opacity: 0, y: 200, scale: 0 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 500, scale: 0 }}
            transition={{ ease: 'easeOut' }}
          >
            <div className="w-100">
              <Swiper
                modules={[EffectCoverflow, Autoplay]}
                effect='coverflow'
                slidesPerView={1}
                className='swiper-story'
                onBeforeInit={slider => swiperRef.current = slider}
                initialSlide={from}
                autoplay={{ delay: stories[from].length > 1 ? stories[from].reduce((a, b) => a?.duration + b?.duration) : stories[from][activeStory]?.duration }}
                onSlideChange={(e) => {
                  setState({ startFrom: e.activeIndex });
                  setState({ activeStoryIndex: 0 })
                }}
              >
                {stories.map((i, index) => (
                  <SwiperSlide key={index}>
                    <ReactInstaStories
                      currentIndex={activeStoryIndex}
                      stories={stories[index]}
                      width={432}
                      height={'100vh'}
                      defaultInterval={3000}
                      keyboardNavigation
                      onAllStoriesEnd={() => {
                        if ((index + 1) >= stories.length) {
                          closeStory();
                        }
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </motion.div>
        </>
      }
    </AnimatePresence>
  )
}


export const StoryRender = ({ state, i, index, ii, action, setState, totalAllStory, totalStoryEach, index2, swiperRef, closeStory }) => {
  const [showLikeAnim, showLikeAnimSet] = useState(false);
  const [thisNewsAlreadyLiked, thisNewsAlreadyLikedSet] = useState(false);
  const router = useRouter();
  function getRandomHeartPosition() {
    const randomNumber = Math.random() * Math.random();
    return randomNumber * 1000;
  }

  useEffect(() => {
    setTimeout(function () {
      // Hide the address bar!
      window.scrollTo(0, 1);
    }, 0);    
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (state?.startFrom !== index) {
        action('pause')
      } else {
        action('play')
      }
    }, 100)
  }, [state?.startFrom]);

  // console.log(state)
  // console.log(index2,totalStoryEach)
  useEffect(() => {
    const ifThisNewsAlreadyLiked = localStorage.getItem(`${i?.title.split(" ").join("-")}-${index2}`);
    const hideLikeAnim = () => {
      showLikeAnimSet(false)
      // console.log('stop')
    };


    if (showLikeAnim && !ifThisNewsAlreadyLiked) {
      setTimeout(hideLikeAnim, 5000);
      localStorage.setItem(`${i?.title.split(" ").join("-")}-${index2}`, true);
      thisNewsAlreadyLikedSet(true);
    } else {
      setTimeout(hideLikeAnim, 5000);
      thisNewsAlreadyLikedSet(false);
    }

    if (ifThisNewsAlreadyLiked) {
      thisNewsAlreadyLikedSet(true)
    }

    return () => clearTimeout(hideLikeAnim);
  }, [showLikeAnim])

  return (
    <div id="the-story">
      <div className="d-flex align-items-center position-absolute" style={{ top: 32, left: 16, zIndex: 10 }}>
        <div style={{ width: 28, height: 28, borderRadius: '50%', overflow: 'hidden' }}>
          <img className="img-full" style={{}} src={i?.NewsStoryDetail?.dailyInsights?.thumbnail?.mediaItemUrl || i?.NewsStoryDetail?.discover?.thumbnail?.mediaItemUrl} />
        </div>
        <p className="font-chakrapetch ml-2 mb-0">{i?.title}</p>
      </div>

      <div className="position-fixed d-flex justify-content-center w-100" style={{ bottom: 0, left: 0, zIndex: 1001 }}>
        <div style={{ borderTop: '1px solid #000', width: 432, borderBottom: '1px solid #000' }} className='px-4 py-3 bg-grey d-flex justify-content-between position-relative'>
          <div className="d-flex" style={{ gap: 16 }}>
            <div className="pointer" onClick={() => {
              if (!showLikeAnim) {
                showLikeAnimSet(true);
              }
            }}>
              {thisNewsAlreadyLiked ? <img src={heartAnim} width={24} height={24} /> : <IconHeart />}
            </div>

            <CopyToClipboard text={`${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}?story=${i?.title}_${index2}`}
              onCopy={() => toast.success('Link Copied', { toastId: 'copy-link' })}>
              <div className="pointer">
                <IconShare2 />
              </div>
            </CopyToClipboard>
          </div>

          <div onClick={() => closeStory()} className="position-absolute pointer" style={{ right: 24, top: -64 / 2 }}>
            <IconClose style={{ width: 64, height: 64 }} />
          </div>
        </div>
      </div>

      <div style={{ background: '#080808', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '100%' }} className='position-relative'>
          {ii?.image?.mediaItemUrl ?
            <img src={ii?.image?.mediaItemUrl} style={{ objectFit: 'cover', width: '100%' }} />
            :
            <div style={{ width: '90%' }}>
              <video style={{ objectFit: 'cover', width: '100%' }} autoPlay={state.startFrom === index}>
                <source src={ii?.video?.mediaItemUrl} />
              </video>
            </div>
          }
        </div>
      </div>

      <div className="nav-story">
        <div className="nav-story-left"
          onClick={() => (index2 === 0 && index === 0) ? setState({ activeStoryIndex: 0 }) : index2 > 0 ? setState({ activeStoryIndex: index2 - 1 }) : swiperRef.current.slidePrev()}
        />
        <div className="nav-story-right"
          onClick={() => index2 < totalStoryEach ? setState({ activeStoryIndex: index2 + 1 }) : index < totalAllStory ? swiperRef.current.slideNext() : setState({ isOpenStory: false, activeStoryIndex: 0 })} />
      </div>

      <AnimatePresence>
        {
          showLikeAnim &&
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='d-flex justify-content-center'>
            <div className="raining-card flex-wrap justify-content-between">
              {new Array(18).fill(undefined).map((_, i) => (
                <div key={i} className="the-heart" style={{ transform: `translateY(${getRandomHeartPosition()}px)` }}>
                  <img src={heartAnim} />
                </div>
              ))}
            </div>
            {/* DESKTOP */}
            <div className="raining-card flex-wrap justify-content-between">
              {new Array(38).fill(undefined).map((_, i) => (
                <div key={i} className="the-heart" style={{ transform: `translateY(${getRandomHeartPosition()}px)` }}>
                  <img src={heartAnim} />
                </div>
              ))}
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}