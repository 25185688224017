/** Libs */
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectCoverflow } from "swiper"
import { useRef, useState } from "react"
import Link from "next/link"
/** Global Components */

/** Local Components */

/** Assets */
import logoCakWhite from "assets/img/icon/misc/logo-cak-white.png"
import whiteRing from "assets/img/grfx/group/white-ring.svg"
import blueBall from "assets/img/grfx/group/blue-ball.svg"
import redStar from "assets/img/grfx/group/red-star.svg"
/** Utils */
import { useScrollAnim } from "components/hooks/hooks"
import { Button } from "components/anti"
import { linkNewsAnalysis } from "components/page_url"
import { IconArrowRight } from "components/icons"

export default function BannerSlider({ data = [], newsVariant }) {
  // State
  const [state, stateSet] = useState({
    currentSlide: 0,
  })
  // Hooks
  const [ref, anim] = useScrollAnim()
  const swiperRef = useRef(null)
  // Func
  const setState = (obj) => {
    if (typeof obj !== "object") return state
    stateSet((prev) => {
      return { ...prev, ...obj }
    })
  }

  // function convertTextBold(title) {
  //   let descriptionSample = [];
  //   let splittedDescription = title.split("*");
  //   splittedDescription.forEach((i, ii) => (ii !== 0 && ii !== (splittedDescription.length - 1)) && descriptionSample.push(i))
  //   return `${splittedDescription[0]}${descriptionSample.map((i, ii) => ii % 2 ? i : `<strong>${i}</strong>`).join("")}${splittedDescription[splittedDescription.length - 1]}`
  // }

  // Use Effect
  return (
    <div className="banner-slider" ref={ref}>
      <div className="grfx">
        <img src={whiteRing} className={`whiteRing ${anim(data.length + 6)}`} />
        <img src={blueBall} className={`blueBall ${anim(data.length + 7)}`} />
        <img src={redStar} className={`redStar ${anim(data.length + 8)}`} />
      </div>

      <Swiper
        initialSlide={state?.currentSlide}
        modules={[EffectCoverflow, Autoplay]}
        // autoplay={{ delay: 8000 }}
        effect="coverflow"
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            spaceBetween: 16,
            slidesPerView: 1.5,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              // depth: 50,
              depth: 0,
              modifier: 0,
              slideShadows: true,
            },
          },
          768: {
            spaceBetween: 32,
            slidesPerView: 2.8,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 0,
              slideShadows: true,
            },
          },
          1024: {
            spaceBetween: 32,
            slidesPerView: 3.8,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 0,
              slideShadows: true,
            },
          },
          1440: {
            spaceBetween: 32,
            slidesPerView: 4.8,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 0,
              slideShadows: true,
            },
          },
        }}
        onSlideChange={(e) => setState({ currentSlide: e.realIndex })}
        loop
        speed={1000}
        grabCursor
        onBeforeInit={(swiper) => (swiperRef.current = swiper)}
      >
        {data.map((item, key) => (
          <SwiperSlide
            key={`swiper-banner-${key}`}
            className={`${state.currentSlide === key && "banner-slide-active"}`}
          >
            <Link
              href={`${linkNewsAnalysis}/${
                !newsVariant ? item?.news[0]?.slug : item?.slug
              }}`}
            >
              <div className={`w-100 banner-inside h-100 ${anim(key + 1)}`}>
                <div className="w-100 h-100">
                  <img className="img-full" src={item?.image?.mediaItemUrl} />
                </div>
                {!newsVariant && (
                  <div className="todays-pick-cak-logo">
                    <div className="cak-logo">
                      <img className="img-full" src={logoCakWhite} />
                    </div>
                  </div>
                )}
                {!newsVariant ? (
                  <div className="banner-text-container">
                    <>
                      <div className="banner-badge-shadow">TODAY'S PICK</div>
                      <div className="banner-badge">TODAY'S PICK</div>
                    </>

                    <div
                      className="banner-title"
                      dangerouslySetInnerHTML={{ __html: item?.title }}
                    />
                    <Button
                      variant="normal"
                      link={`${linkNewsAnalysis}/${item?.news[0]?.slug}}`}
                      className="mt-3"
                    >
                      {item?.button?.text}
                      <IconArrowRight className="ml-2" />
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="news-title w-100">
                      <h3 className="text-cak-yellow m-0">
                        News <span>&</span>
                      </h3>
                      <h3 className="text-white ml-5 text-right">Analysis</h3>
                    </div>
                    <div className="banner-text-container-news">
                      <div
                        className="banner-title font-semibold"
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.title?.length > 40
                              ? `${item?.title.slice(0, 40)}...`
                              : item?.title,
                        }}
                      />
                      <div>
                        <Button
                          link={`${linkNewsAnalysis}/${item?.slug}}`}
                          variant="normal"
                          className="btn-normal-outline py-1"
                        >
                          READ MORE
                          <IconArrowRight className="ml-2" />
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={`banner-slide-indicator mt-4 ${anim(data.length + 1)}`}>
        {new Array(data.length).fill(undefined).map((_, i) => (
          <div
            onClick={() => swiperRef.current.slideToLoop(i)}
            className={`banner-slide-indicator-item ${
              state.currentSlide === i && "active"
            }`}
            key={`banner-slide-${i}`}
          />
        ))}
      </div>
    </div>
  )
}
